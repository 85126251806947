import { useState } from "react";
import {
  AlignItems,
  Box,
  ColorPreset,
  ColorScheme,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  JustifyContent,
  Layer,
  P,
  PlainButton,
  ProgressBar,
  ProgressBarColor,
  ProgressBarVariant,
  Space,
  Text,
  TypePreset,
  TypeScale,
  Visibility,
  XYGrid,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  Header,
  HEADER_HEIGHT,
} from "src/components/layout/Headers/FlowPageHeader";
import ThreatMetrixProfiler from "src/technical-integrations/threat-metrix";

interface TwoPanelLayoutWithProgressBarProps {
  totalSteps: number;
  stepNumber: number;
}

export interface TwoPanelLayoutProps {
  leftPanel: React.ReactNode;
  rightPanel: React.ReactNode;
  totalSteps?: number;
  stepNumber?: number;
  isEmailPreview?: boolean;
  isFormPreview?: boolean;
  maximiseLeftPanelWidth?: boolean;
  centerRightPanelContent?: boolean;
  preventLeftPanelContentShrink?: boolean;
  overflowHidden?: boolean;
}

export interface MobilePreviewProps {
  rightPanel: React.ReactNode;
  isEmailPreview?: boolean;
  isFormPreview?: boolean;
  header?: string;
}
/**
 *
 * @deprecated As part of UXI acccessibility layouts project this component will be replaced by RequestPaymentTwoPanelLayout
 */
export const TwoPanelLayout: React.FC<TwoPanelLayoutProps> = ({
  leftPanel,
  rightPanel,
  totalSteps,
  stepNumber,
  isEmailPreview,
  isFormPreview,
  maximiseLeftPanelWidth,
  centerRightPanelContent,
  preventLeftPanelContentShrink,
  overflowHidden,
}) => (
  <Box height="100vh" bg={ColorPreset.BackgroundLight_02}>
    <ThreatMetrixProfiler />
    <Layer
      mode="relative"
      css={{ overflow: overflowHidden ? "hidden" : undefined }}
    >
      {stepNumber && totalSteps ? (
        <PanelHeaderWithProgressBar
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
      ) : (
        <PanelHeaderWithOutProgressBar />
      )}
      <Box bg={ColorPreset.BackgroundLight_02}>
        <XYGrid templateColumns={["1fr", null, null, "1fr 1fr"]}>
          <Box
            spaceBelow={6}
            spaceAbove={4}
            spaceBefore={[1, null, null, 0]}
            spaceAfter={[1, null, null, 0]}
            bg={ColorPreset.BackgroundLight_02}
            css={{
              paddingTop: HEADER_HEIGHT,
            }}
            minWidth={preventLeftPanelContentShrink ? [0, null, null, 700] : 0}
            layout="flex"
            justifyContent={[
              JustifyContent.Center,
              null,
              null,
              JustifyContent.FlexEnd,
            ]}
          >
            <Box
              spaceBefore={[null, null, 6, 6, null]}
              spaceAfter={[null, null, 6, 4, 8]}
              spaceBelow={[1, null, null, 6]}
              flexGrow={maximiseLeftPanelWidth ? 1 : 0}
            >
              {leftPanel}
            </Box>
          </Box>
          <Box
            spaceAbove={4}
            spaceBefore={[1, null, null, 0]}
            spaceAfter={[1, null, null, 0]}
            bg={ColorPreset.BackgroundLight_02}
            css={{
              paddingTop: HEADER_HEIGHT,
              "@media (max-width: 1120px)": {
                display: "none",
              },
            }}
            layout={centerRightPanelContent ? "flex" : "block"}
            justifyContent={
              centerRightPanelContent ? JustifyContent.Center : [null]
            }
          >
            <Box
              spaceBefore={
                centerRightPanelContent ? [2] : [null, null, null, 4, 8]
              }
              spaceAfter={
                centerRightPanelContent ? [2] : [null, null, null, 6, null]
              }
              maxWidth={[null, null, null, "500px", "550px"]}
              spaceBelow={6}
            >
              {(isEmailPreview || isFormPreview) && (
                <P
                  weight={FontWeight.Bold}
                  color={ColorPreset.TextOnLight_01}
                  preset={TypePreset.Heading_04}
                >
                  <Trans id="request-payment.what-customer-sees">
                    What your customer sees
                  </Trans>
                </P>
              )}
              {rightPanel}
            </Box>
          </Box>
        </XYGrid>
      </Box>
    </Layer>
  </Box>
);

export const MobilePreview: React.FC<MobilePreviewProps> = ({
  rightPanel,
  header,
  isEmailPreview,
  isFormPreview,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const { i18n } = useLingui();
  const screenWidth = screen.width;

  return (
    <Visibility visible={["block", null, null, "none", null]}>
      <Box
        data-testid="mobile-preview"
        spaceAbove={2}
        borderRadius={1}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_05}
        gutterV={2}
        gutterH={1.5}
      >
        <PlainButton
          effect={HoverEffect.TextDecoration}
          onClick={() => setShowPreview(!showPreview)}
        >
          <Box layout="inline-flex" alignItems={AlignItems.Center}>
            <P
              weight={FontWeight.Bold}
              size={TypeScale.Size_03}
              color={ColorPreset.TextOnLight_01}
            >
              {header
                ? header
                : isEmailPreview || isFormPreview
                  ? `${i18n._(
                      t({
                        id: "request-payment.what-customer-sees",
                        message: "What your customer sees",
                      })
                    )}`
                  : `${i18n._(
                      t({
                        id: "request-payment.our-top-tip",
                        message: "Our top tip",
                      })
                    )}`}
            </P>
            <Space layout="inline" h={1} />
            <Icon name={!showPreview ? Glyph.ChevronDown : Glyph.ChevronUp} />
          </Box>
        </PlainButton>
        {showPreview && (
          <Box
            spaceAbove={1}
            maxWidth={[
              `${screenWidth - 82}px`,
              "550px",
              "550px",
              "500px",
              "550px",
            ]}
          >
            {rightPanel}
          </Box>
        )}
      </Box>
    </Visibility>
  );
};

export const PanelHeaderWithProgressBar: React.FC<
  TwoPanelLayoutWithProgressBarProps
> = ({ totalSteps, stepNumber }) => (
  <Layer mode="absolute" css={{ width: "100%" }}>
    <Header
      progressBar={
        <ProgressBar
          variant={ProgressBarVariant.Solid}
          colorScheme={ColorScheme.OnLight}
          color={ProgressBarColor.Info}
          value={stepNumber}
          max={totalSteps}
          label={
            <Visibility visible="none">
              <Text id="progress-bar">progress bar</Text>
            </Visibility>
          }
          aria-labelledby="progress-bar"
        />
      }
    />
  </Layer>
);

export interface PanelHeaderWithOutProgressBarProps {
  showBackButton?: boolean;
}

export const PanelHeaderWithOutProgressBar: React.FC<
  PanelHeaderWithOutProgressBarProps
> = ({ showBackButton }) => (
  <Layer mode="absolute" top="0" left="0" css={{ width: "100%" }}>
    <Header showBackButton={showBackButton} />
  </Layer>
);
