import { ErrorInterface, Error, Errors } from "@gocardless/api/utils/error";
import { t } from "@lingui/macro";
import { I18n } from "@lingui/core";

import { NotificationPayload } from "src/hooks/useToastNotification";
import { Currency } from "src/common/currencies";
import { currencyToScheme, Scheme } from "src/common/scheme";

export const toKebabCase = (tabName: string): string =>
  tabName.replace(/\s/g, "-").toLowerCase();

export const getAmountTypeError = (
  error: Error,
  qualifiesForPaymentLimitIncrease: boolean,
  currency: string
): string => {
  const link =
    "https://support.gocardless.com/hc/en-gb/requests/new?ticket_form_id=360000040469";
  const metadata = error.metadata;
  // To maintain the old functionality until all amount errors have a `metadata` object.
  // Should remove once all amount errors include a `metadata` object.
  if (!metadata) {
    if (qualifiesForPaymentLimitIncrease) {
      return `Amount is greater than the maximum permitted amount - contact GoCardless using this ${link} if you would like to take larger payments`;
    } else {
      return "Amount is greater than the maximum permitted amount";
    }
  }

  // Assuming that `metadata` object will have only a single key-value pair,
  // where the `value` will always be the actual amount.
  const amountInPence = Number(Object.values(metadata)[0]) || 0;

  const amount = amountInPence / 100;
  const errorType = error?.message;
  if (
    errorType === "amount_greater_than_maximum_error" ||
    errorType === "is greater than the permitted scheme maximum"
  ) {
    if (qualifiesForPaymentLimitIncrease) {
      return `Amount is greater than the maximum permitted amount of ${amount} ${currency} - contact GoCardless using this ${link} if you would like to take larger payments`;
    } else {
      return `Amount is greater than the maximum permitted amount of ${amount} ${currency}`;
    }
  } else if (errorType === "greater_than_scheme_max") {
    return `Amount is greater than the maximum scheme permitted amount of ${amount} ${currency}`;
  }
  return "";
};

export const getSendInviteButtonTitle = (i18n: I18n, count: number) =>
  count <= 1
    ? i18n._(
        t({
          id: "send-invite",
          message: `Send invite ( ${count} )`,
        })
      )
    : i18n._(
        t({
          id: "send-invites",
          message: `Send invites ( ${count} )`,
        })
      );

/**
 * Accepts an error payload returned by the backend when a POST to the
 * Authorisation Requests endpoint fails. This function parses the Authorisation
 * Requests which have failed to be created (given with an index of the passed
 * emails), and then matches those failures to the email addresses which have
 * failed validation. This allows us to provide more helpful error messaging to
 * merchants, showing them the name of the emails which have failed validation
 * in the UI.
 *
 * For example if the list of emails is:
 * ["test1@test.com", "test2@test.com", "test3@test.com"]
 *
 * And the request_pointers in the errors array are as follows:
 * "/authorisation_requests/0/email"
 * "/authorisation_requests/2/email"
 *
 * Then we can display "test1@test.com" and "test3@test.com" to the user in an
 * error message.
 */
export const getInvalidEmailsFromAuthRequestErrorPayload = (
  errorPayload: {
    notificationPayload: NotificationPayload;
    formError: "" | Errors | undefined;
    errorJSON: ErrorInterface | undefined;
  },
  emails: string[]
): string[] => {
  if (!Array.isArray(errorPayload.errorJSON?.errors)) {
    return [];
  }

  const invalidEmailIndices = errorPayload.errorJSON?.errors
    .filter(
      ({ field, message }) => field === "email" && message === "is not valid"
    )
    .map(({ request_pointer }) => request_pointer.match(/\d+/)?.map(Number)[0]);
  if (invalidEmailIndices && invalidEmailIndices.length > 0) {
    const emailErrors = invalidEmailIndices
      .filter((value) => value !== undefined)
      .map((index) => emails[index as number] || "");
    return emailErrors;
  }
  return [];
};

const PAYMENT_TIMINGS: Record<Scheme, number> = {
  [Scheme.Ach]: 4,
  [Scheme.Autogiro]: 10,
  [Scheme.Bacs]: 5,
  [Scheme.Becs]: 4,
  [Scheme.BecsNz]: 6,
  [Scheme.Betalingsservice]: 10,
  [Scheme.Pad]: 6,
  [Scheme.SepaCore]: 6,
  [Scheme.FasterPayments]: 0,
  [Scheme.PayTo]: 0,
};

export const getPaymentTiming = (currency: Currency) =>
  PAYMENT_TIMINGS[currencyToScheme[currency]];
