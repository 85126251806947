import {
  Box,
  ColorPreset,
  FontWeight,
  P,
  PlainLink,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import {
  EstimatePaymentFeeListQueryParams,
  useEstimatePaymentFeeList,
} from "@gocardless/api/dashboard/estimate-payment-fee";
import { Trans } from "@lingui/macro";

import { Currency } from "src/common/currencies";

export interface EstimatedPaymentFeeProps {
  amount: number;
  currency: Currency;
  geo: string;
}
const getPricingUrl = (geo?: string): string => {
  if (!geo) return "";

  const urlAddress = "https://gocardless.com/";
  const urlFragment = "#the-real-exchange-rate";

  switch (geo) {
    case "GB":
      return `${urlAddress}pricing${urlFragment}`;
    case "DE":
      return `${urlAddress}de-de/preise${urlFragment}`;
    case "FR":
      return `${urlAddress}fr-fr/tarifs${urlFragment}`;
    case "AU":
      return `${urlAddress}en-au/pricing${urlFragment}`;
    case "CA":
      return `${urlAddress}en-ca/pricing${urlFragment}`;
    case "DK":
      return `${urlAddress}da-dk/priser${urlFragment}`;
    case "ES":
      return `${urlAddress}es-es/tarifas${urlFragment}`;
    case "IE":
      return `${urlAddress}en-ie/pricing${urlFragment}`;
    case "NZ":
      return `${urlAddress}en-nz/pricing${urlFragment}`;
    case "SE":
      return `${urlAddress}sv-se/prissattning${urlFragment}`;
    case "US":
      return `${urlAddress}en-us/pricing${urlFragment}`;
    default:
      return `${urlAddress}pricing${urlFragment}`;
  }
};

export const EstimatedPaymentFee: React.FC<EstimatedPaymentFeeProps> = ({
  amount,
  currency,
  geo,
}) => {
  const params: EstimatePaymentFeeListQueryParams = {
    amount: Math.round(amount * 100),
    source_currency: currency,
  };
  const response = useEstimatePaymentFeeList(params);
  const feeEstimate = response.data?.estimate_payment_fees;

  if (amount <= 0) {
    return null;
  }

  if (!feeEstimate || !feeEstimate.fx_currency) {
    return <></>;
  }

  if (feeEstimate.net_amount && feeEstimate.net_amount < 0) {
    return <></>;
  }

  return (
    <Box
      data-testid="estimated-payment-fee"
      borderColor={ColorPreset.BorderOnLight_05}
      borderWidth={1}
      borderRadius={1}
      gutterH={2}
      gutterV={2}
      bg={ColorPreset.BackgroundLight_02}
    >
      <Box maxWidth="500px">
        <Box layout="block">
          <P preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_01}>
            {amount} {currency} =
          </P>
          <P
            preset={TypePreset.Body_02}
            color={ColorPreset.TextOnLight_01}
            weight={FontWeight.Bold}
          >
            {Number(feeEstimate.fx_net_amount) / 100} {feeEstimate.fx_currency}{" "}
            <Trans id="request-payments.estimated-payout">
              Estimated Payout
            </Trans>
          </P>
        </Box>
        <Box>
          <Space layout="block" v={1.5} />
          <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
            <Trans id="request-payments.transaction-fee-vat-incl">
              Transaction Fee (includes VAT)
            </Trans>
          </P>
          {feeEstimate.fee_amount && (
            <P
              preset={TypePreset.Body_02}
              color={ColorPreset.TextOnLight_01}
              weight={FontWeight.Bold}
            >
              {feeEstimate.fee_amount / 100} {feeEstimate.fee_currency}
            </P>
          )}
        </Box>
        <Space layout="block" v={1} />
        <Box layout="block">
          <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
            <Trans id="request-payments.to-be-converted-domestic">
              To be converted to your domestic currency
            </Trans>
          </P>
          <P
            preset={TypePreset.Body_02}
            color={ColorPreset.TextOnLight_01}
            weight={FontWeight.Bold}
          >
            {Number(feeEstimate.net_amount) / 100} {feeEstimate.fee_currency}
          </P>
        </Box>
        <Space layout="block" v={1} />

        <Box layout="block">
          <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
            <Trans id="request-payments.we-use-the">We use the</Trans>{" "}
            <PlainLink
              decoration="underline"
              href={getPricingUrl(geo)}
              target="_blank"
            >
              <Text>
                <Trans id="request-payments.real-exchange-rate">
                  real exchange rate
                </Trans>
              </Text>
            </PlainLink>{" "}
            <Trans id="request-payments.to-convert-payouts">
              to convert all payouts.
            </Trans>
          </P>
          <P
            preset={TypePreset.Body_02}
            color={ColorPreset.TextOnLight_01}
            weight={FontWeight.Bold}
          >
            1 {feeEstimate.fee_currency} ={" "}
            {feeEstimate.exchange_rate?.substr(0, 7)} {feeEstimate.fx_currency}
          </P>
        </Box>
        <Space layout="block" v={1.5} />

        <Box layout="block">
          <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
            <Trans id="request-payment.estimate-fee-disclaimer">
              This is for reference only. The actual amount you receive will be
              determined by the exchange rate on the day of the payout.
            </Trans>
          </P>
        </Box>
      </Box>
    </Box>
  );
};
