import {
  Field,
  Label,
  SelectInput,
  DefaultListItemProps,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import {
  Controller,
  FieldValues,
  Path,
  UseFormStateProps,
} from "react-hook-form";
import { PlanResource } from "@gocardless/api/dashboard/types";
import { Trans, t } from "@lingui/macro";
import { useMemo, useState } from "react";
import { useLingui } from "@lingui/react";
import debounce from "lodash/debounce";

import { getRecurringPaymentDescription } from "../utils";

import { getInputErrorStatus } from "src/utils/inputValidation";

export type PlanList = [PlanResource, ...PlanResource[]];

interface PlanFieldProps<T extends FieldValues>
  extends Omit<UseFormStateProps<T>, "ref"> {
  name: Path<T>;
  planList: PlanList;
  error?: string;
  disabled?: boolean;
  onChange?: (value: string | number) => void;
}

export const PlanListField = <T extends FieldValues>({
  error,
  name,
  planList,
  control,
  disabled,
  onChange,
}: PlanFieldProps<T>) => {
  const { i18n } = useLingui();
  const [searchInput, setSearchInput] = useState("");

  const unFilteredItems = planList.map((plan) => ({
    value: plan.id ?? "",
    label: `${plan.name} (${getRecurringPaymentDescription(plan)})`,
  }));

  const items = useMemo(() => {
    if (!searchInput) {
      return unFilteredItems;
    }
    return unFilteredItems?.filter(
      ({ label, value: itemValue }: DefaultListItemProps) =>
        label.toLowerCase().includes(searchInput?.toLowerCase()) ||
        `${itemValue}`.toLowerCase().includes(searchInput?.toLowerCase())
    );
  }, [searchInput, unFilteredItems]);

  const onFilter = debounce((e) => {
    setSearchInput(e.target.value);
  }, 300);

  const placeholderSearchText = i18n._(
    t({
      id: "seach-a-subscription-template",
      message: "Search for a subscription template",
    })
  );

  const placeholderTriggerText = i18n._(
    t({
      id: "choose-a-subscription-template",
      message: "Choose a Subscription template",
    })
  );

  const emptyResultMessage = i18n._(
    t({
      message: "That search returned no results",
      id: "search.index.returned_no_results",
    })
  );

  return (
    <Field>
      <Label htmlFor="planId">
        <Trans id="choose-a-subscription-template">
          Choose a Subscription template
        </Trans>
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field, ...props }) => {
          const selectedItem =
            unFilteredItems.find((item) => item.value === field.value) || null;
          return (
            <SelectInput<DefaultListItemProps>
              searchInputProps={{
                placeholder: placeholderSearchText,
                onChange: onFilter,
              }}
              virtualize={items.length > 100}
              onClose={() => setSearchInput("")}
              placeholder={placeholderTriggerText}
              maxHeight={300}
              emptyMessage={emptyResultMessage}
              items={items}
              status={getInputErrorStatus(!!props.fieldState.error)}
              id={name}
              disabled={disabled}
              {...field}
              value={selectedItem}
              onChange={(selected) => {
                field.onChange(selected?.value ?? "");
                onChange?.(selected?.value ?? "");
              }}
            />
          );
        }}
      />
      {error && <Hint status={FormFieldStatus.Danger}>{error}</Hint>}
    </Field>
  );
};
